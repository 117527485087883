import * as React from "react"
import { Link } from "gatsby"
import FoF from "../images/Error 404.svg"

const NotFoundPage = () => {
  return (
      <div style={{ position:"fixed", left:"50%", top:"50%", transform:"translate(-50%,-50%)",
        width:"100%", textAlign:"center", backgroundColor:"var(--background-color)"}}>
        <title>Not found</title>
        <p style={{fontSize:"3vw"}}><Link to="/">Back to homepage</Link></p>
        <img style={{maxWidth:"50%"}} src={FoF} alt={"Error 404 page image"}></img>
        <p style={{fontSize:"2vw"}}>
          Sorry we couldn’t find what you were looking for.
        </p>
      </div>
  )
}

export default NotFoundPage
